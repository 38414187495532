@keyframes buganimation
{
    0% {
        font-size: 150px;
        transform: rotate(0deg); 
        bottom: 20px;
        left: 50px; 
    }
    25% {
        font-size: 200px;
        transform: rotate(95deg); 
        top: 20px;
        left: 50px; 
    }
    50% {
        font-size: 150px;
        transform: rotate(175deg);
        bottom: 20px;
        left: 50px; 
    }
    75% {
        font-size: 200px;
        transform: rotate(280deg); 
        bottom: 20px;
        right: 50px; 
    }
    100% {
        font-size: 150px;
        transform: rotate(360deg); 
        bottom: 20px;
        left: 50px; 
    }
}

#bug-icon {
  margin: 60px; 
  font-size: 150px; 
  color: green;
  position: absolute;
  bottom: 20px;
  left: 50px; 
  animation: buganimation 10s infinite;
}