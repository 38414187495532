.graph-panel {
  position: absolute;
  top: 70px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 790px) {
  .graph-panel {
    position: absolute;
    top: 50px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    max-height: 100%;
    max-width: 100%;
  }
}
