.ql-showHtml:after {
  content: '[source]';
}
.ql-editor strong {
  font-weight: bold;
}
.ql-editor {
  overflow: auto;
  max-height: 400px;
}
